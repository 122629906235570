import _isPrimitive from "is-primitive";
var exports = {};
var isPrimitive = _isPrimitive;

exports = function isEqual(a, b) {
  if (!a && !b) {
    return true;
  }

  if (!a && b || a && !b) {
    return false;
  }

  var numKeysA = 0,
      numKeysB = 0,
      key;

  for (key in b) {
    numKeysB++;

    if (!isPrimitive(b[key]) || !a.hasOwnProperty(key) || a[key] !== b[key]) {
      return false;
    }
  }

  for (key in a) {
    numKeysA++;
  }

  return numKeysA === numKeysB;
};

export default exports;